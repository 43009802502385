.scroller .MuiTabs-flexContainer {
    overflow-x: auto;
}

.attributeBox {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 2px;
    align-items: center;
    border-radius: 10px;
}

.attributeBox h2 {
    width: 20%;
    font-weight: 400;
    font-size: 20px;
}

.attributeBox .MuiRating-root {
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.reportIcon {
    font-size: 70px !important;
}

.pdfCont {
    width: 100%;
    padding: 20px 0;
}

@media only screen and (max-width: 900px) {
    .attributeBox .MuiRating-root {
        display: flex;
        justify-content: space-between;
        width: 70%;
    }

    .reportIcon {
        font-size: 35px !important;
    }

    .attributeBox h2 {
        width: 20%;
        font-size: 13px !important;
    }

    .attrTitle {
        display: none;
    }
}