/* .count {
    border: solid 5px #c11a83;
    max-width: 700px;
    margin: auto;
} */

.buttonsCarousel {
  background-color: var(--secondary) !important;
}

.carouselBox {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carouselBox h2 {
  color: white;
  font-size: 45px;
}

.LogoContainerCont {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
}

.LogoContainer {
  display: inline-block;
  animation: slide 20s infinite linear;
}

/* .LogoContainerCont:hover .LogoContainer {
  animation-play-state: paused;
} */

.logo {
  box-sizing: border-box;
  white-space: nowrap;
  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: white;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
}

.logo img {
  height: 90px;
  margin: 0px 20px;
  min-width: 80px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

@media (max-width: 1024px) {
  .carouselBox {
    padding: 30px;
  }

  .buttonsCarousel {
    display: none !important;
  }
  .logo {
    padding: 10px;
    margin: 10px;
  }

  .logo img {
    height: 60px;
    margin: 0px 20px;
    min-width: 80px;
  }
}
