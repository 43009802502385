:root {
  /* -----colors----- */
  --primary: #002369;
  --secondary: #96d228;
  --text-primary: #000000;
  --text-secondary: #ffffff;
  /* -----fonts----- */
  --normal-font: 20px;
  --Chat-Text: 18px;
  --button: 20px;
  --form-text: 20px;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo-Regular"),
    url("/src/assets/Fonts/Cairo/Cairo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Sora";
  src: local("Sora-VariableFont_wght"),
    url("/src/assets/Fonts/Sora/Sora-VariableFont_wght.ttf") format("truetype");
}

.MuiTypography-root,
.MuiButton-root {
  font-family: inherit !important;
}

* {
  margin: 0;
  padding: 0;
  /* font-family: "Kumbh-Regular" !important; */
  font-family: inherit;
  text-decoration: none;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  transition: 0.2s;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Sora", sans-serif !important;
}
