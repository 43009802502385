.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  min-height: 100vh;
}

.counter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}

.CounterItem {
  width: 80%;
  /* height: 150px; */
  position: relative;
  margin: auto;
  color: var(--primary);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.CounterItem .outer {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}

.CounterItem .outer .inner {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(255, 255, 255, 0.7),
    0.5px 0.5px 0px rgba(0, 0, 0, 0.15), 0px 12px 10px -10px rgba(0, 0, 0, 0.05); */
}

#number {
  font-weight: 700;
  color: #010102;
  font-size: 43px;
}

.counterText {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 0 0;
  font-weight: 700;
}

circle {
  fill: none;
  stroke: #96d228;
  stroke-width: 5px;
  stroke-dasharray: 750;
  stroke-dashoffset: 750;
  animation: 4s ease-out forwards;
  animation-delay: 0s;
}

@keyframes counter {
  100% {
    stroke-dashoffset: 0;
  }
}

.CounterItem svg {
  position: absolute;
  top: 0;
  left: calc(50% - 75px);
}

@media (max-width: 1400px) {
  .counter {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .counter {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
  }

  .inner img {
    height: 40px !important;
  }

  #number {
    font-weight: 700;
    color: #010102;
    font-size: 35px;
  }

  .counterText {
    text-align: center;
    font-size: 15px;
    margin: 10px 0 0 0;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
