/* .count {
    border: solid 5px #c11a83;
    max-width: 700px;
    margin: auto;
} */

.buttonsCarousel {
  background-color: var(--secondary) !important;
}

.carouselBox {
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carouselBox h2 {
  color: white;
  font-size: 45px;
}

.schoolLogo {
  width: auto !important;
  height: 100px;
}

.homeVideo {
  height: 400px;
}

@media (max-width: 1024px) {
  .carouselBox {
    padding: 30px;
  }

  .buttonsCarousel {
    display: none !important;
  }

  .homeVideo {
    height: 300px;
  }
}
