.SearchBar .MuiOutlinedInput-root {
    background-color: white;
    border-radius: 30px;
}

.Input {
    width: 100%;
    margin: 10px;
}

.SearchBar {
    max-width: 600px;
}

.SearchBar fieldset {
    border: 0;
}

.searchIcon {
    color: rgb(158, 158, 158);
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    font-size: 35px !important;
}

.searchIcon:hover {
    background-color: rgba(128, 128, 128, 0.1);
}

.searchIcon:active {
    background-color: rgba(128, 128, 128, 0.15);
}