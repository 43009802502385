.studentPhoto {
    width: 250px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.loadingSmallBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 35px;
}