.SocialFooterIcon {
  font-size: 30px !important;
  cursor: pointer;
  color: white;
  margin: 0 2px;
}

.SocialFooterIcon:hover {
  /* color: #595c61; */
}

.footerLinks {
  color: white;
  font-size: 16px;
  margin-left: 20px;
  margin-top: 10px;
}

.footerLinks li {
  cursor: pointer;
  margin-bottom: 5px;
  font-size: 15px;
}

.footerLinks li:hover {
  text-decoration: underline;
}

.footerListIcon {
  padding: 5px;
  display: flex;
  margin-top: 5px;
  font-size: 15px;
  color: white;
  max-width: 250px;
  align-items: center;
}

.footerListIcon div {
  border-radius: 50%;
  background-color: rgb(48, 48, 48);
  height: 30px;
  width: 30px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.footerListIcon svg {
  font-size: 20px;
}

.mobile {
  display: none !important;
}

@media (max-width: 900px) {
  .SocialFooterIcon {
    margin-left: 10px;
  }

  .footerListIcon,
  .desktop {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}
