.modalStyle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  box-shadow: 24px;
  padding: 32px;
  border-radius: 10px;
  max-width: 900px;
  max-height: 90vh;
  overflow: auto;
}

.FormTitle {
  font-size: 30px !important;
  font-weight: 600 !important;
  text-align: center;
}

.MuiRating-root span {}

@media (max-width: 900px) {
  .modalStyle {
    width: 90%;
    padding: 15px;
  }

  .FormTitle {
    font-size: 20px !important;
  }
}